import React from "react";
import { Features } from "../components/Features";
import { PageLayout } from "../components/PageLayout";
import { featuresPageHeroImage } from "../images";

const FeaturesPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${featuresPageHeroImage})`}
      heroOverlayLeadElement="Major Features"
      heroOverlaySecondaryElement="Checking all the boxes."
    >
      <Features />
    </PageLayout>
  );
};

export default FeaturesPage;
