import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Heading2 } from "./Heading2";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";

export const Features: React.FC = () => {
  return (
    <>
      <PageHeader
        primary="Features"
        secondary="How we add value to your business."
      />
      <Section>
        <Heading2>Features</Heading2>
        <Paragraph>
          When you're looking to make a change there are several things that are
          important. Our solution includes the following important features that
          we know you'll find useful:
          <dl>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              It's A Browser &amp; Web-Based Solution
            </dt>
            <dd>
              We leverage your browser with a single page application (SPA) to
              create frontend that accesses our web APIs on the server via HTTP.
              This is the most common, fully modern architecture.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              You Can Choose Your Hosting Options
            </dt>
            <dd>
              Our original solution runs 100% in the Azure cloud. But we also
              support using an on-premises IIS server or even an Internet-hosted
              IIS server. All of our deliveries can be Cloud-native or run on
              your Windows server of choice. It's up to you.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              Uses Modern Languages
            </dt>
            <dd>
              Our solutions use C# on the backend and Javascript/Typescript on
              the frontend. There's a large talent pool for these technologies
              and the tech is well-understood. You minimize your risks and lower
              your costs.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              Preserves Your Business Assets
            </dt>
            <dd>
              Our solution keeps the value in your business assets &ndash; your
              code and data &ndash; by translating them into modern forms that
              you will use going forward. You don't have to start over again.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              Reduces Your Training Burden
            </dt>
            <dd>
              Our solution strikes a balance designed to control your training
              efforts: your digital assets are converted, but typically into
              easily readable forms that mimic the appearance and/or
              organization of the original. So, your converted code retains its
              original comments and variable names. Your converted forms and
              menus have all of the same text and names. Your data fields are
              the same. Your current staff sees familiar business items while
              your new staff sees familiar technology.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              Designed for Extensibility
            </dt>
            <dd>
              We know you're going to keep moving forward. Our design keeps an
              eye toward the future by including extensibility points for the
              client application and the server APIs. You won't be stuck down
              the road by choosing us.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              Works With Other Modern Technologies
            </dt>
            <dd>
              Our solution is normal .Net and can interoperate with or co-exist
              with any .Net-based technologies you already have or may acquire
              in the future.
            </dd>
            <dt className="tos_feature_name">
              <FontAwesomeIcon
                icon={faCheckSquare}
                className="tos_feature_bullet"
              />{" "}
              It's Devops Compatible
            </dt>
            <dd>
              We deliver an Azure-hosted automated build and deployment pipeline
              for your solution with the code. This pipeline works even if you
              decide to host outside of Azure and includes support for automated
              test execution.
            </dd>
          </dl>
        </Paragraph>
      </Section>
    </>
  );
};
